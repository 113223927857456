import type { LayoutProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { adsRequest } from '../../utils/ads-request';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import { useCursorContext } from '../../contexts/CursorContext';

const AdvertisementSlot = dynamic<any>(() => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot), {
  ssr: false,
});

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'auto',
}));

const Sides = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up(1200)]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap',
    height: 'fit-content',
    justifyContent: 'center',
    padding: theme.spacing(7, 2, 2),
  },
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  margin: theme.spacing(0, 0, 4),
}));

const AdsLeft = (props: any) => {
  return (
    <AdvertisementSlotStyled
      slotIdentifier="idhb-left-300"
      elevation={1}
      alignment="right"
      {...props}
    />
  )
}

const AdsLeftMiddle = (leftSize: any) => {
  return (
    <AdvertisementSlotStyled
      slotIdentifier="idhb-left-small-300"
      variant="300x250"
      alignment="right"
      elevation={1}
    />
  )
}

const AdsRight = (props: any) => {
  return (
    <AdvertisementSlotStyled
      slotIdentifier="idhb-right-300"
      alignment="left"
      elevation={1}
      {...props}
    />
  )
}

const AdsRightMiddle = () => {
  return (
    <AdvertisementSlotStyled
      slotIdentifier="idhb-right-small-300"
      variant="300x250"
      alignment="left"
      elevation={1}
    />
  )
}

export const LayoutB = (props: LayoutProps) => {
  const { children } = props;
  const router = useRouter();
  const xlCustom = useMediaQuery('(min-width:1560px)', { noSsr: true });
  const lgCustom = useMediaQuery('(max-width:1559px) and (min-width:1280px)', { noSsr: true });
  const mdCustom = useMediaQuery('(max-width:1279px) and (min-width:1200px)', { noSsr: true });
  const [leftSize, setLeftSize] = React.useState(0);
  const [rightSize, setRightSize] = React.useState(0);
  const [banners, setBanners] = React.useState<string[]>([]);
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { panel } = useCursorContext();

  const layoutAdsElements: Record<string, boolean> = {
    'idhb-left-300': !(componentOptions.component_layout_left_ads_disabled || componentOptions.component_layout_left_ads_tablet_disabled || componentOptions.component_layout_left_ads_mobile_disabled),
    'idhb-left-small-300': !(componentOptions.component_layout_left_middle_ads_disabled || componentOptions.component_layout_left_middle_ads_tablet_disabled || componentOptions.component_layout_left_middle_ads_mobile_disabled),
    'idhb-right-300': !componentOptions.component_layout_right_ads_disabled || false,
    'idhb-right-small-300': !componentOptions.component_layout_right_middle_ads_disabled || false,
  };
  
  const bannerConditions = [
    { key: 'idhb-left-300', condition: (size: number) => size > 0 },
    { key: 'idhb-left-small-300', condition: (size: number) => size >= 300 },
    { key: 'idhb-right-300', condition: (size: number) => size > 0 },
    { key: 'idhb-right-small-300', condition: (size: number) => size >= 300 },
  ];

  React.useEffect(() => {
    if (xlCustom) {
      setLeftSize(300);
      setRightSize(300);
    } else if (lgCustom) {
      setLeftSize(160);
      setRightSize(160);
    } else if (mdCustom) {
      setLeftSize(120);
      setRightSize(120);
    } else {
      setLeftSize(0);
      setRightSize(0);
    }
  }, [xlCustom, lgCustom, mdCustom]);
  
  React.useEffect(() => {
    const updatedBanners = [];
  
    for (const { key, condition } of bannerConditions) {
      const isElementEnabled = layoutAdsElements[key];
  
      if (isElementEnabled && condition(leftSize)) {
        updatedBanners.push(key);
      }
    }
  
    setBanners(updatedBanners);
  }, [leftSize, rightSize, componentOptions]);

  React.useEffect(() => {
    if(banners.length > 0 && !componentOptions.component_ads_disabled) adsRequest(banners);
  }, [banners]);

  React.useEffect(() => {
    const { events } = router;
    const handleRouteChange = () => {
      adsRequest(banners);
    }

    events.on('routeChangeComplete', handleRouteChange);
    events.on('hashChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
      events.off('hashChangeComplete', handleRouteChange);
    }
  }, [router]);

  return (
    <Root>
      {panel ? (
        <Sides>
          <AdsLeft variant={`${leftSize}x600`} />
          {leftSize >= 300 && !componentOptions.component_layout_left_middle_ads_disabled &&  (
             <AdsLeftMiddle />
          )}
        </Sides>
      ) : (
        <>
          {!componentOptions.component_ads_disabled && leftSize > 0 && (
            <Sides>
              {(lg && !componentOptions.component_layout_left_ads_disabled) && <AdsLeft variant={`${leftSize}x600`} />}
              {(isBetweenMdAndLg && !componentOptions.component_layout_left_ads_tablet_disabled) && <AdsLeft variant={`${leftSize}x600`} />}
              {(mdDown && !componentOptions.component_layout_left_ads_mobile_disabled) && <AdsLeft variant={`${leftSize}x600`} />}
              {leftSize >= 300 && !componentOptions.component_layout_left_middle_ads_disabled &&  (
                <>
                  {(lg && !componentOptions.component_layout_left_middle_ads_disabled) && <AdsLeftMiddle />}
                  {(isBetweenMdAndLg && !componentOptions.component_layout_left_middle_ads_tablet_disabled) && <AdsLeftMiddle />}
                  {(mdDown && !componentOptions.component_layout_left_middle_ads_mobile_disabled) && <AdsLeftMiddle />}
                </>
              )}
            </Sides>
          )}
        </>
      )}
      {children}
      {panel ? (
        <Sides>
          <AdsRight variant={`${rightSize}x600`} />
          {rightSize >= 300 && !componentOptions.component_layout_right_middle_ads_disabled && (
            <AdsRightMiddle />
          )}
        </Sides>
      ) : (
        <>
          {!componentOptions.component_ads_disabled && rightSize > 0 && (
            <Sides>
              {(lg && componentOptions.component_layout_right_ads_disabled) && <AdsRight variant={`${rightSize}x600`} />}
              {(isBetweenMdAndLg && componentOptions.component_layout_right_ads_tablet_disabled) && <AdsRight variant={`${rightSize}x600`} />}
              {(mdDown && componentOptions.component_layout_right_ads_mobile_disabled) && <AdsRight variant={`${rightSize}x600`} />}
              {rightSize >= 300 && !componentOptions.component_layout_right_middle_ads_disabled && (
                <>
                  {(lg && !componentOptions.component_layout_right_middle_ads_disabled) && <AdsRightMiddle />}
                  {(isBetweenMdAndLg && !componentOptions.component_layout_right_middle_ads_tablet_disabled) && <AdsRightMiddle />}
                  {(mdDown && !componentOptions.component_layout_right_middle_ads_mobile_disabled) && <AdsRightMiddle />}
                </>
              )}
            </Sides>
          )}
        </>
      )}
      
    </Root>
  );
}
