import type { LayoutProps } from '../../types';

/**
 * This component is used as fallback nested layout in case no layout component for a page
 * is set during a BigBang site build.
 *
 * This layout can be used as example "NestedLayout" component within our NPM framework.
 *
 * @param props LayoutProps
 * @constructor
 */
export function DefaultNestedLayout(props: LayoutProps) {
  const { children } = props;

  return <>{children}</>;
}
