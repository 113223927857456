export const adsRequest = (banners: Array<string>) => {
  if (banners.length >= 1) {
    window.idhb = window?.idhb || {};
    window.idhb.que = window.idhb?.que || [];
    window.idhb.que.push(() => {
      console.info('[hl]', banners);
      window.idhb.requestAds({
        // slotIds is optional. If not specified, ads for all configured slots on the page will be requested
        slotIds: banners,
      });
    });
  }
};